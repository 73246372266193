// services
import { StorageKeys, Storage } from "./storage";
// types
import type { LiteralUnion } from "type-fest";
import type { OAuthState, OAuthStateObj } from "../types/user";

const OAUTH_STATE_EXPIRE_TIME = 1000 * 60 * 5; // 5 min

export const isValidOAuthState = (state: LiteralUnion<OAuthState, string>) => {
	const oAuthStateObj = Storage.get(StorageKeys.oAuthState);

	return oAuthStateObj?.state === state && oAuthStateObj.expires >= Date.now();
};

export const createAndStoreOAuthState = (): OAuthState => {
	const oAuthState = globalThis.crypto.randomUUID();
	const oAuthStateObj = {
		state: oAuthState,
		expires: Date.now() + OAUTH_STATE_EXPIRE_TIME,
	} as const satisfies OAuthStateObj;
	Storage.set(StorageKeys.oAuthState, oAuthStateObj);

	return oAuthState;
};

export const removeOAuthState = () => (Storage.remove(StorageKeys.oAuthState));

export const clearExpiredOAuthState = () => {
	const oAuthStateObj = Storage.get(StorageKeys.oAuthState);

	if (oAuthStateObj !== undefined && oAuthStateObj.expires < Date.now()) {
		Storage.remove(StorageKeys.oAuthState);
	}
};
