import { Component, Children } from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
// cmp
import SubPage from "./playground/sub-page";
import NavigationDrawer from "./navigation/navigation-drawer";
import Svg from "./svg";
import { muiTheme } from "@local/theme";
// types
import type { ReactNode } from "react";

type Props = {
	activeRoute?: string;
	activePage?: string;
	hideAppBar?: boolean;
	hideNavigationDrawer?: boolean;
	//hideNavigationMenu?: boolean;
	navigationDrawerDocked?: boolean;
	iconElementRight?: ReactNode;
	screen?: string;
	children?: ReactNode;
	title?: string | any | null; // TODO
	onBack?: (() => void) | null;
};

type State = {
	navigationDrawerOpen: boolean;
	hideAppBar: boolean;
	navigationDrawerDocked: boolean;
	initiallyDocked: boolean;
};

class PagePanel<P extends Props = Props, S extends State = State> extends Component<P, S> {

	constructor(props: Props) {
		super(props);

		this.state = {
			navigationDrawerOpen: false,
			hideAppBar: props.hideAppBar && !props.onBack,
			navigationDrawerDocked: props.navigationDrawerDocked,
			initiallyDocked: props.navigationDrawerDocked,
		};

		// bind handlers them to this
		this.openNavigationDrawer = this.openNavigationDrawer.bind(this);
		this.handleNavigationDrawerOpenChanged = this.handleNavigationDrawerOpenChanged.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (this.props.hideAppBar !== prevProps.hideAppBar || this.props.onBack !== prevProps.onBack) {
			this.setState({
				hideAppBar: this.props.hideAppBar && !this.props.onBack,
			});
		}
	}

	openNavigationDrawer() {
		this.setState((prevState) => ({
			navigationDrawerOpen: !prevState.navigationDrawerOpen,
			navigationDrawerDocked: !prevState.navigationDrawerOpen,
		}));
	}

	handleNavigationDrawerOpenChanged(open) {
		this.setState({
			navigationDrawerOpen: open,
		});
	}

	override render() {
		const navigationDrawerWidth = 300;
		let navigationDrawerDocked = this.state.navigationDrawerDocked;
		let navigationDrawerOpen = this.state.navigationDrawerOpen;
		let contentLeftMargin = 0;
		const state = this.state;
		if (this.state.initiallyDocked) {
			if (navigationDrawerDocked) {
				navigationDrawerOpen = true;
				state.navigationDrawerOpen = true;
			}
			if (navigationDrawerDocked && !this.props.hideNavigationDrawer) {
				contentLeftMargin = navigationDrawerWidth;
			}
		} else {
			navigationDrawerDocked = false;
		}

		let title = this.props.title;
		let onBack = this.props.onBack;
		const subPages = Children.map(this.props.children, (child) => {
			if (!child || child.type !== SubPage) {
				return null;
			}
			// its a SubPage
			if (child.props.page && child.props.page !== this.props.activePage) {
				return null;
			}
			onBack ||= child.props.onBack;
			title ||= child.props.title;
			return child;
		});

		return (
			<>
				{!this.state.hideAppBar &&
					<AppBar style={{ width: `calc(100% - ${contentLeftMargin}px)` }}>
						<Toolbar>
							{onBack
								? <IconButton onClick={onBack}><Svg src="navigation/arrowLeft.svg" color={muiTheme.palette.primary.contrastText} /></IconButton>
								: this.props.hideNavigationDrawer
									? null
									: <IconButton onClick={this.openNavigationDrawer}><Svg src="navigation/navigationMenuIcon.svg" color={muiTheme.palette.primary.contrastText} /></IconButton>
							}
							<Typography variant="h6" noWrap={true} style={{ marginLeft: "12px", width: "100%" }}>
								{title}
							</Typography>
							<div style={{ flexGrow: 1 }} />
							{this.props.iconElementRight}
						</Toolbar>
					</AppBar>
				}
				{!this.props.hideNavigationDrawer &&
					<NavigationDrawer
						open={navigationDrawerOpen}
						activeRoute={this.props.activeRoute}
						onOpenChanged={this.handleNavigationDrawerOpenChanged}
						width={navigationDrawerWidth}
						docked={navigationDrawerDocked}
					/>
				}
				<div style={{ display: "flex", marginLeft: contentLeftMargin, paddingLeft: "0.25%" }}>
					{subPages}
				</div>
				<main style={{ marginLeft: contentLeftMargin }}>
					<div className="contentPage">
						{Children.map(this.props.children, (child) => {
							if (!child || child.type === SubPage) {
								return null;
							}
							return child;
						})}
					</div>
				</main>
			</>
		);
	}

}

PagePanel.defaultProps = {
	navigationDrawerDocked: false,
	activeRoute: "",
	activePage: "",
	hideAppBar: false,
	hideNavigationDrawer: false,
	//hideNavigationMenu: false,
	iconElementRight: null,
	children: null,
	title: null,
	onBack: null,
};

PagePanel.propTypes = {
	activeRoute: PropTypes.string,
	activePage: PropTypes.string,
	hideAppBar: PropTypes.bool,
	hideNavigationDrawer: PropTypes.bool,
	//hideNavigationMenu: PropTypes.bool,
	navigationDrawerDocked: PropTypes.bool,
	iconElementRight: PropTypes.node,
	children: PropTypes.node,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	onBack: PropTypes.func,
};

export default PagePanel;
