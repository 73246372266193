import { EventEmitter } from "events";
// services
import { getServerOption } from "./serverOption";
import { isValidOAuthState, createAndStoreOAuthState, removeOAuthState, clearExpiredOAuthState } from "./oAuthState";
import { stopAccessTokenAutoRefresh, restartAccessTokenAutoRefresh } from "./accessTokenAutoRefresh";
import Gupport from "./gupport";
import { Storage } from "./storage";
import { isRocBuildUrl, getRefererUrl } from "./utils";
// types
import type { ReadonlyDeep } from "type-fest";
import type { /*MsgData,*/ MsgDataWithPayload } from "../types/roc-ws";
import type { MsgResponseLogin, LoginUserGupport, Level } from "../types/gupport";
import type { UserId } from "../types/user";

class User extends EventEmitter {

	#ready = false;
	#userId: UserId | undefined = undefined;
	#user: ReadonlyDeep<LoginUserGupport> | undefined = undefined;
	#error: Error | null = null; // eslint-disable-line no-unused-private-class-members

	constructor() {
		super();

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleGupportDisconnected = this.handleGupportDisconnected.bind(this);
		this.handleGupportLoginFailed = this.handleGupportLoginFailed.bind(this);

		Gupport.on("ready", this.handleGupportReady);
		Gupport.on("disconnected", this.handleGupportDisconnected);
		Gupport.on("loginFailed", this.handleGupportLoginFailed);
	}

	public get ready() {
		return this.#ready;
	}

	public get userId() {
		return this.#userId;
	}

	public get user() {
		return this.#user;
	}

	public get userData() {
		return {
			ready: this.#ready,
			userId: this.#userId,
			user: this.#user,
		} as const;
	}

	private handleGupportReady(msg: MsgResponseLogin): void {
		this.#userId = msg.payload.data.sub;
		this.#user = msg.payload.data;

		this.#ready = true;
		this.emit("ready");
		this.emit("changed", this.userData);
	}

	private handleGupportDisconnected() {
		// this.#status = "disconnected";
		// TODO
		// this.emit("changed", this.getUserData());
	}

	private async handleGupportLoginFailed(msgData: MsgDataWithPayload): Promise<void> {
		await this.logoutCleanup(msgData.payload.data ?? "Login failed, unknown reason");
	}

	login(): void {
		const { channel, redirectUrl: serverRedirectUrl } = getServerOption();

		const state = createAndStoreOAuthState();

		const params = new URLSearchParams({
			state: state,
			channel: channel,
		});
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}

		const url = `${serverRedirectUrl}?${params.toString()}` as const;
		globalThis.location.href = url;
	}

	public async logout(): Promise<void> {
		await this.logoutCleanup();
		this.#logout();
	}

	public async logoutCleanup(errorMessage: string | undefined = undefined): Promise<void> {
		this.#ready = false;

		await Gupport.disconnect();

		stopAccessTokenAutoRefresh();

		this.#userId = undefined;
		this.#user = undefined;
		this.#error = (errorMessage === undefined) ? new Error(errorMessage) : null;
		this.emit("changed", this.userData);

		Storage.logoutCleanup();
	}

	#logout(): void {
		const { channel, revokeUrl } = getServerOption();

		const state = createAndStoreOAuthState();

		const params = new URLSearchParams({
			state: state,
			channel: channel,
		});
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}

		const url = `${revokeUrl}?${params.toString()}` as const;
		window.location.href = url;
	}

	public hasLevel(level: Level) {
		return this.user?.level.includes(level) ?? false;
	}

	public hasAnyLevel(levels: Array<Level>) {
		return levels.some((level) => (this.hasLevel(level)));
	}

	public async manageSearchParams(): Promise<void> {
		const urlSearchParams = new URLSearchParams(globalThis.location.search);
		const state = urlSearchParams.get("state");
		if (state) {
			if (isValidOAuthState(state)) {
				if (urlSearchParams.has("subject") && urlSearchParams.has("displayName") && urlSearchParams.has("expires")) {
					// login
					restartAccessTokenAutoRefresh(Number(urlSearchParams.get("expires")));
				} else {
					// logout
					await this.logoutCleanup();
				}
				removeOAuthState();
			} else {
				console.warn("unknown oauth-state", state);
			}
		}
		clearExpiredOAuthState();
	}

}

export default (new User());
