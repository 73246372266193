import { useState, useEffect, useContext, createContext } from "react";
// services
import User from "../services/user";
// types
import type { ReactElement } from "react";
import type { ReadonlyDeep } from "type-fest";
import type { LoginUserGupport } from "../types/message";
import type { UserId } from "../types/user";

interface UserDataContextT {
	ready: boolean;
	userId: UserId | undefined;
	user: ReadonlyDeep<LoginUserGupport> | undefined;
}

export const DEFAULT_VALUE = {
	ready: false,
	userId: undefined,
	user: undefined,
} as const satisfies UserDataContextT;

const UserDataContext = createContext<UserDataContextT>(DEFAULT_VALUE);

if (process.env.NODE_ENV !== "production") {
	UserDataContext.displayName = "UserDataContext";
}

export const useUserData = () => (useContext(UserDataContext));

type Props = {
	children: ReactElement;
};

export const UserDataProvider = ({ children }: Props) => {
	const [userData, setUserData] = useState(User.userData);

	useEffect(() => {
		User.on("changed", setUserData);

		return () => {
			User.off("changed", setUserData);
		};
	}, []);

	return (
		<UserDataContext.Provider value={userData}>
			{children}
		</UserDataContext.Provider>
	);
};
