import { EventEmitter } from "events";

type ScreenSize = "xl" | "lg" | "md" | "sm" | "xs";
type ScreenSizes = Array<ScreenSize>;

// sizes taken from bootstrap
// see https://github.com/twbs/bootstrap/blob/v3-dev/less/variables.less#L283
// Hint: the order is important! large -> small
const screens = {
	xl: 1600, // Very Large screen / very wide desktop
	lg: 1200, // Large screen / wide desktop
	md: 992, // Medium screen / desktop
	sm: 768, // Small screen / tablet
	xs: 0, // Extra small screen / phone
} as const;

const RESIZE_TIMEOUT = 50;

/**
 * A window size event listener, which emits a screenSizeChanged
 * event whenever browser window is resized.
 *
 * @event screenSizeChanged
 * @event screenChanged
 * @class Responsive
 * @extends EventEmitter
 */
class Responsive extends EventEmitter {

	#willUpdate = false;
	#timeoutId: number | undefined = undefined;
	#screenSizes = Responsive.#getAvailableScreenSizes();

	constructor() {
		super();

		this.handleWindowResize = this.handleWindowResize.bind(this);

		window.addEventListener("resize", this.handleWindowResize);
	}

	static #getAvailableScreenSizes(): ScreenSizes {
		return (Object.keys(screens) as ScreenSizes).filter((screenSize) => (screens[screenSize] <= window.innerWidth));
	}

	static #screenSizesAreDifferent(screenSizesA: ScreenSizes, screenSizesB: ScreenSizes): boolean {
		if (screenSizesA.length !== screenSizesB.length) {
			return true;
		}
		return screenSizesA.some((screenSizeA, index) => (screenSizeA !== screenSizesB[index]));
	}

	private handleWindowResize(): void {
		if (!this.#willUpdate) {
			this.#willUpdate = true;
			window.clearTimeout(this.#timeoutId);
			this.#timeoutId = window.setTimeout(() => {
				this.#willUpdate = false;
				this.emit("screenSizeChanged");
				const newScreenSizes = Responsive.#getAvailableScreenSizes();
				if (Responsive.#screenSizesAreDifferent(newScreenSizes, this.#screenSizes)) {
					this.#screenSizes = newScreenSizes;
					this.emit("screenChanged");
				}
			}, RESIZE_TIMEOUT);
		}
	}

	public getScreenSizes(): ScreenSizes {
		return this.#screenSizes;
	}

}

export default (new Responsive());
