type Props = {
	code: string;
};

const Code = ({ code }: Props) => (
	<pre style={{ whiteSpace: "pre-wrap", marginBottom: 0 }}>
		<code>{code}</code>
	</pre>
);

export default Code;
