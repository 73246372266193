/**
 * Constant file - Contaning basically enum values.
 * @constant constants
 */
const constants = {
	Channel: {
		Hornbach: "hornbach",
		Ozom: "ozom",
		Roc: "roc",
	},
	WsType: {
		Glient: "glient",
		Gupport: "gupport",
		CC: "cc",
	},
	Direction: {
		TX: "tx",
		RX: "rx",
	},
	GatewayModules: {
		gardena: "gardena",
		hue: "hue",
		hue_remote: "hue_remote",
		indego: "indego",
		motion: "motion",
		netatmo: "netatmo",
		nuki: "nuki",
		tado: "tado",
		tuya: "tuya",
		wiz: "wiz",
	},
	RocIdUnderConstruction: "FFFF",
	Appearance: {
		Light: "light",
		Dark: "dark",
		System: "system",
	},
	TableName: {
		RocIdDict: "rocid_dict",
		Grules: "grules",
		GrulesAdvanced: "grules_advanced",
		QuickViewConfig: "quickview_config",
		ApiSchemas: "api_schemas",
	},
	TempUnit: {
		Celsius: "C",
		Fahrenheit: "F",
	},
	Rule: {
		Mode: {
			Create: "create",
			Edit: "edit",
			Duplicate: "duplicate",
		},
		Type: {
			Template: "template",
			Scheduler: "scheduler",
			Advanced: "advanced",
		},
		SchedulerType: {
			General: "general",
			PetFeeder: "petFeeder",
		},
		ParamType: {
			Check: "check",
			Action: "action",
		},
		ItemsType: {
			Checks: "checks",
			Actions: "actions",
		},
		AdvancedParamCategory: {
			Device: "device",
			Time: "time",
			VirtualDevice: "virtual_device",
			Gateway: "gateway",
			Notification: "notification",
		},
		TemplateParamId: {
			SmartMode: "smartmode",
		},
		AdvancedRuleTemplateId: {
			CheckThermostatState: "check_thermostat_state",
		},
		AdvancedRuleErrorMode: {
			BlockedBy: "blockedBy",
			MaxOccurrences: "maxOccurrences",
			RequiresAdditionalChecks: "requiresAdditionalChecks",
		},
		TimezoneReference: {
			Home: "home",
			Manual: "manual",
		},
		VirtualDeviceId: {
			AllCameras: "80C07D1D00000004",
			AllSirens: "80C07D1D00000012",
			Notification: "80C07D1D00000013",
			SunriseSunset: "80C07D1D00000020", // TODO: check naming
		},
	},
	EpDeviceType: {
		None: 0b00,
		Device: 0b01,
		Endpoint: 0b10,
	},
	GeneralCmdIds: {
		ReadAttribute: "00",
		WriteAttribute: "02",
	},
	DataType: {
		Boolean: "10", // true/false
		Bitmap8Bit: "18", // "00"
		UInt8Bit: "20", // "00"
		UInt16Bit: "21", // "0000"
		UInt24Bit: "22", // "000000"
		SInt24Bit: "2A", // "000000"
		Enum8Bit: "30", // "00"
	},
	Caps: {
		Incaps: "incaps",
		Outcaps: "outcaps",
	},
	Kind: {
		User: "user",
		Gateway: "gateway",
	},
	Gateway: {
		Kind: {
			Gateway: "gateway",
			C2C: "c2c",
			GPS: "gps",
		},
		Level: {
			Owner: "owner",
			User: "user",
			Pending: "pending",
		},
		Status: {
			Ready: "ready",
			Unreachable: "unreachable",
			Pairing: "pairing",
		},
		Sku: {
			Tuya: "tuya",
		},
		SunTimeOffset: {
			Min: -120,
			Max: 120,
			Step: 5,
		},
		ReportId: {
			SmartMode: "FFF1",
			Timezone: "FFF2",
			TemperatureUnit: "FFF3",
		},
	},
	Device: {
		Property: {
			PowerLevelStatus: "FF00",
			ReachableStatus: "FF01",
		},
		Value: {
			PowerLevelStatus: {
				Bat: "bat",
				LowBat: "low_bat",
			},
			ReachableStatus: {
				Reachable: "reachable",
				Unreachable: "unreachable",
			},
		},
		Attributes: {
			Property: {
				ZCLVersion: "0000",
				ApplicationVersion: "0001",
				StackVersion: "0002",
				HWVersion: "0003",
				ManufacturerName: "0004",
				ModelIdentifier: "0005",
				FirmwareVersion: "0006",
				PowerSource: "0007",
				DeviceCustomConfigVersion: "FF00",
				DeviceType: "Device Type",
			},
			Value: {
				PowerSource: {
					Unknown: "00",
					Mains1Phase: "01",
					Mains3Phase: "02",
					Battery: "03",
					DCSource: "04",
					EmergencyMainsConstantlyPowered: "05",
					EmergencyMainsTransferSwitch: "06",
				},
				DeviceType: {
					Bosch: "bosch",
					Bticino: "bticino",
					C2gActions: "c2g_actions",
					Gardena: "gardena",
					Hue: "hue",
					HueRemote: "hue_remote",
					Indego: "indego",
					MomitActions: "momitActions",
					Motion: "motion",
					MotionActions: "motionActions",
					Netatmo: "netatmo",
					Nuki: "nuki",
					NukiActions: "nukiActions",
					Ring: "ring",
					Tado: "tado",
					Tuya: "tuya",
					Wiz: "wiz",
					ZigbeeActions: "zbActions",
					Zwave: "Z-Wave",
					ZwaveActions: "zwaveActions",
				},
			},
		},
	},
	DeviceInfoValues: ["hide", "device_attributes", "rocid_private", "rocid_public"],
	Units: {
		Percent: "%",
		Degree: "°",
		Watt: "W",
		KiloWattHour: "kWh",
		Lux: "lx",
		HectoPascal: "hPa",
		PartsPerMillion: "ppm",
		PartsPerBillion: "ppb",
		Gram: "g",
		MicroGramPerCubicMeter: "µg/m³",
	},
	InvalidValue: "--",
	PairingDeviceCategory: {
		Alexa: "alexa",
		Device: "device",
		Gardena: "gardena",
		Ghome: "ghome",
		Hue: "hue",
		HueRemote: "hue_remote",
		Indego: "indego",
		Motion: "motion",
		Netatmo: "netatmo",
		Nuki: "nuki",
		Tado: "tado",
		Tuya: "tuya",
		Wifi: "wifi",
		Zigbee: "zigbee",
		Zwave: "zwave",
	},
	PairingCategoryType: {
		Manufacturer: "manufacturer",
		DeviceType: "type",
	},
	Tabs: {
		Devices: {
			Devices: "devices",
			Rooms: "rooms",
		},
		Rules: {
			All: "all",
			Templates: "templates",
			Scheduler: "scheduler",
			Advanced: "advanced",
		},
		History: {
			All: "all",
			Event: "event",
			Rule: "rule",
			Alert: "alert",
			Report: "report",
		},
		Gateway: {
			General: "general",
			Rooms: "rooms",
			Users: "users",
			Modules: "modules",
			Advanced: "advanced",
		},
	},
	HistoryTags: {
		Alert: "alert",
		Event: "event",
		Rule: "rule",
		Scene: "scene",
		Gateway: "gateway",
	},
	ZigbeeDeviceNetworkInfo: {
		RelationShips: {
			Parent: "00",
			Child: "01",
			Sibling: "02",
			Special1: "03",
			Special2: "04",
		},
		Notations: {
			Parent: "P",
			Child: "C",
			Sibling: "S",
			Special: "*",
		},
		LineStyle: {
			Solid: 0,
			Dashed: 15,
			Dotted: 3,
		},
	},
} as const;

export default constants;
