import i18n from "./i18n";
// types
import type { Language } from "../types/misc";

// Language depending

/**
 * @deprecated use `useLanguage` hook or `i18n.resolvedLanguage as Language` directly
 */
export const UI_LANGUAGE = i18n.resolvedLanguage as Language;

// Format depending

/**
 * @deprecated use `useLocale` hook
 */
const LOCALE = new Intl.Locale(new Intl.NumberFormat().resolvedOptions().locale);

/**
 * @deprecated use `useSorter` hook
 */
export const sortAlphabetically = new Intl.Collator(LOCALE.baseName, { sensitivity: "base" }).compare;
