import { useState, useEffect, useMemo, useContext, createContext } from "react";
// services
import { StorageKeys, Storage } from "../services/storage";
// types
import type { ReactNode } from "react";

const defaultValue = {
	expertMode: false,
	toggleExpertMode: () => {},
};

const ExpertModeContext = createContext(defaultValue);

if (process.env.NODE_ENV !== "production") {
	ExpertModeContext.displayName = "ExpertModeContext";
}

export const useExpertMode = () => (useContext(ExpertModeContext));

type Props = {
	children: ReactNode;
};

export const ExpertModeProvider = ({ children }: Props) => {
	const [expertMode, setExpertMode] = useState(Storage.get(StorageKeys.expertMode));

	useEffect(() => {
		const handleExpertModeChanged = (newExpertMode: boolean) => {
			setExpertMode(newExpertMode);
		};

		Storage.on(`${StorageKeys.expertMode}Changed`, handleExpertModeChanged);

		return () => {
			Storage.off(`${StorageKeys.expertMode}Changed`, handleExpertModeChanged);
		};
	}, []);

	const value = useMemo(() => ({
		expertMode: expertMode,
		toggleExpertMode: () => (Storage.set(StorageKeys.expertMode, !expertMode)),
	}), [expertMode]);

	return (
		<ExpertModeContext.Provider value={value}>
			{children}
		</ExpertModeContext.Provider>
	);
};
