import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
// cmp
import Svg from "../svg";
import Code from "../code";
// context
import { useExpertMode } from "../../context/ExpertModeContext";
// services
// @ts-expect-error: window.appInfo
import AppInfo from "@local/appInfo";

type Props = {
	onExpertModeChanged: () => void;
};

const NavigationLogo = ({ onExpertModeChanged }: Props) => {
	const { t } = useTranslation();

	const { toggleExpertMode } = useExpertMode();

	const [open, setOpen] = useState(false);
	const clickCountRef = useRef(0);
	const timeoutIdRef = useRef<number | undefined>(undefined);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	const resetTimeout = () => {
		window.clearTimeout(timeoutIdRef.current);

		timeoutIdRef.current = window.setTimeout(() => {
			clickCountRef.current = 0;
		}, 1000);
	};

	const handleClick = () => {
		clickCountRef.current++;
		if (clickCountRef.current === 10) {
			toggleExpertMode();
			onExpertModeChanged();
			setOpen(true);
		} else {
			resetTimeout();
		}
	};

	return (
		<Box component="div" sx={{height: "64px", padding: "4px 12px"}}>
			<Svg src="navdrawer-logo.svg" onClick={handleClick} style={{width: "100%", height: "100%"}} />
			<Dialog
				id="dlg-navigation-expert-mode"
				fullWidth={true}
				open={open}
			>
				<DialogTitle>{t("expertMode.title")}</DialogTitle>
				<DialogContent sx={{padding: "8px 22px"}}>
					<p>{t("expertMode.msg")}</p>
					<Code code={JSON.stringify(AppInfo, null, 2)} />
				</DialogContent>
				<DialogActions>
					<Button className="btn-dlg-action-ok" onClick={() => (setOpen(false))}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default NavigationLogo;
