import { forwardRef } from "react";
import InlineSvg from "react-inlinesvg";
import { useTheme } from "@mui/material/styles";

// services
import { images, hasImage } from "@local/theme";
// types
import type { Props as SVGProps } from "react-inlinesvg";

type Props = SVGProps & {
	size?: number;
};

const Svg = forwardRef<SVGElement, Props>(({ src, size = undefined, color = undefined, style = {}, ...other }, ref) => {
	const theme = useTheme();

	const _src = (theme.palette.mode === "dark" && hasImage(`dark/${src}`)) ? images(`dark/${src}`) : images(src);

	if (_src === undefined) {
		return null;
	}

	const _style = (color === undefined) ? style : {...style, color: color};

	if (size) {
		return (
			<InlineSvg
				innerRef={ref}
				src={_src}
				width={size}
				height={size}
				style={_style}
				{...other}
			/>
		);
	}

	return (
		<InlineSvg innerRef={ref} src={_src} style={_style} {...other} />
	);
});

Svg.displayName = "SVG";

export default Svg;
