import { Children } from "react";
// types
import type { ReactNode } from "react";

type Props = {
	width?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	page?: string; // Used in PagePanel
	children?: ReactNode;
};

const SubPage = ({ width = "100%"/*, page = undefined*/, children = null }: Props) => (
	<div style={{ width: width, marginTop: "65px" }}>
		{Children.map(children, (child) => (child))}
	</div>
);

export default SubPage;
