import { useState, useEffect } from "react";
import { StorageKeys, Storage } from "../services/storage";
import type { Appearance } from "../types/misc";

const useAppearance = () => {
	const [appearance, setAppearance] = useState(Storage.get(StorageKeys.appearance));

	useEffect(() => {
		const handleAppearanceChanged = (newAppearance: Appearance) => {
			setAppearance(newAppearance);
		};

		Storage.on("appearanceChanged", handleAppearanceChanged);

		return () => {
			Storage.off("appearanceChanged", handleAppearanceChanged);
		};
	}, []);

	const handleSetAppearance = (newAppearance: Appearance | null) => {
		if (newAppearance) {
			Storage.set(StorageKeys.appearance, newAppearance);
		}
	};

	return {
		appearance: appearance,
		setAppearance: handleSetAppearance,
	};
};

export default useAppearance;
