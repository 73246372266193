import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Drawer, Divider, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Skeleton } from "@mui/material";
// cmp
import NavigationLogo from "./navigation-logo";
import NavigationDrawerMenu from "./navigation-drawer-menu";
import ConnectionFailedDialog from "./connection-failed-dialog";
import Svg from "../svg";
// services
import User from "../../services/user";
// types
import type { ReadonlyDeep } from "type-fest";
import type { WithTranslation } from "react-i18next";
import type { LoginUserGupport } from "../../types/gupport";

type Props = WithTranslation & {
	open: boolean;
	docked: boolean;
	activeRoute: string;
	width?: string | number;
	onOpenChanged?: ((open: boolean) => void) | null;
};

type State = {
	user: ReadonlyDeep<LoginUserGupport> | undefined;
	open: boolean;
};

class NavigationDrawer extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			user: User.user,
			open: props.open,
		};

		this.handleUserReady = this.handleUserReady.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleExpertModeChanged = this.handleExpertModeChanged.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
	}

	override componentDidMount() {
		User.on("ready", this.handleUserReady);
	}

	override componentDidUpdate(prevProps: Props) {
		if (this.props.open !== prevProps.open) {
			this.setState({
				open: this.props.open,
			});
		}
	}

	override componentWillUnmount() {
		User.off("ready", this.handleUserReady);
	}

	handleUserReady() {
		this.setState({
			user: User.user,
		});
	}

	handleClose() {
		this.setState({
			open: false,
		}, () => {
			if (this.props.onOpenChanged) {
				this.props.onOpenChanged(this.state.open);
			}
		});
	}

	handleExpertModeChanged() {
		this.forceUpdate();
	}

	async handleLogout() {
		await User.logout();
	}

	override render() {
		const { t, open, docked, activeRoute, width } = this.props;

		return (
			<Drawer
				variant={docked ? "persistent" : "temporary"}
				open={open}
				onClose={this.handleClose}
				PaperProps={{component: "nav", style: {width: width}}}
			>
				<div>{/* div needed here */}
					<ConnectionFailedDialog />
					<NavigationLogo onExpertModeChanged={this.handleExpertModeChanged} />
					<Divider />
					<List disablePadding={true}>
						<ListItem>
							<ListItemText
								primary={this.state.user?.user_name ?? <Skeleton />}
								secondary={this.state.user?.username ?? <Skeleton />}
								primaryTypographyProps={{noWrap: true}}
								secondaryTypographyProps={{noWrap: true}}
							/>
							<ListItemSecondaryAction>
								<IconButton title={t("menu.logout")} edge="end" onClick={this.handleLogout}><Svg src="navigation/logout.svg" /></IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Divider />
						<NavigationDrawerMenu activeRoute={activeRoute} />
					</List>
				</div>
			</Drawer>
		);
	}

}

NavigationDrawer.defaultProps = {
	width: 300,
	onOpenChanged: null,
};

NavigationDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	docked: PropTypes.bool.isRequired,
	activeRoute: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	onOpenChanged: PropTypes.func,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(NavigationDrawer);
