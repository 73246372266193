import type { RouterProviderProps } from "react-router-dom";
import deepmerge from "deepmerge";
// types
import type { MergeDeep } from "type-fest";

export const ROUTER_PROVIDER_FUTURE = {
	// v7_fetcherPersist: true,
	// v7_normalizeFormMethod: true,
	// v7_partialHydration: true,
	// v7_relativeSplatPath: true,
	// v7_skipActionStatusRevalidation: true,
	v7_startTransition: true,
} as const satisfies RouterProviderProps["future"];

export const merge = (...params: Array<object>): object => (Object.assign({}, ...params));

export const mergeDeep = <O1, O2>(obj1: O1, obj2: O2): MergeDeep<O1, O2> => (
	deepmerge<O1, O2>(obj1, obj2, { arrayMerge: (_destinationArray, sourceArray) => (sourceArray) }) as MergeDeep<O1, O2>
);

export const formatBytes = (bytes: number, decimals: number = 2): string => {
	if (!bytes) {
		return "0 Bytes";
	}
	const k = 1000;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] as const;
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i] as (typeof sizes)[number]}`;
};

export const compareObjects = (o1: object, o2: object): boolean => {
	for (const p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	for (const p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	return true;
};

export const isRocBuildUrl = () => (globalThis.location.origin === "https://rocbuild.blob.core.windows.net");

export const getRefererUrl = () => (`${globalThis.location.origin}${globalThis.location.pathname}${globalThis.location.search}`);

export const BBCODE_BOLD_REGEX = /\[b](.+?)\[\/b]/;
