// cmp
import PagePanel from "./page-panel";
// services
import Responsive from "../services/responsive";
// types
import type { ReactNode } from "react";

type Props = {
	activeRoute?: string;
	activePage?: string;
	hideAppBar?: boolean;
	hideNavigationDrawer?: boolean;
	//hideNavigationMenu?: boolean;
	navigationDrawerDocked?: boolean;
	iconElementRight?: ReactNode;
	screen?: string;
	children?: ReactNode;
	title?: string | any | null; // TODO
	onBack?: (() => void) | null;
};

type State = {
	navigationDrawerOpen: boolean;
	hideAppBar: boolean;
	navigationDrawerDocked: boolean;
	initiallyDocked: boolean;
};

class ResponsivePagePanel extends PagePanel<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state.navigationDrawerDocked = Responsive.getScreenSizes().includes("lg");
		this.state.initiallyDocked = this.state.navigationDrawerDocked;

		this.handleScreenChanged = this.handleScreenChanged.bind(this);
	}

	override componentDidMount() {
		Responsive.on("screenChanged", this.handleScreenChanged);
	}

	override componentWillUnmount() {
		Responsive.off("screenChanged", this.handleScreenChanged);
	}

	override componentDidUpdate(prevProps: Props) {
		if (this.props.hideAppBar !== prevProps.hideAppBar || this.props.onBack !== prevProps.onBack) {
			this.setState({
				hideAppBar: this.props.hideAppBar && !this.props.onBack,
			});
		}
	}

	handleScreenChanged() {
		const docked = Responsive.getScreenSizes().includes("lg");
		this.setState({
			navigationDrawerDocked: docked,
			initiallyDocked: docked,
		});
	}

}

export default ResponsivePagePanel;
