import { useContext, createContext } from "react";
import PropTypes from "prop-types";
// hooks
import useStorage, { StorageKeys } from "../hooks/useStorage";
// services
import { config } from "@local/theme";
// types
import type { ReactElement } from "react";
import type { ServerOption } from "../types/config";

const ServerOptionContext = createContext<ServerOption>(config.serverOptions[0]!);

if (process.env.NODE_ENV !== "production") {
	ServerOptionContext.displayName = "ServerOptionContext";
}

export const useServerOption = () => (useContext(ServerOptionContext));

type Props = {
	children: ReactElement;
};

export const ServerOptionProvider = ({ children }: Props) => {
	const [selectedBackendServer] = useStorage(StorageKeys.selectedBackendServer);

	const serverOption = config.serverOptions.find((serverOption) => (serverOption.id === selectedBackendServer)) ?? config.serverOptions[0]!;

	return (
		<ServerOptionContext.Provider value={serverOption}>
			{children}
		</ServerOptionContext.Provider>
	);
};

ServerOptionProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
