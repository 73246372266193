import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import NavigationNestedMenu from "./navigation-nested-menu";
import Svg from "../svg";
// contexts
import { useExpertMode } from "../../context/ExpertModeContext";
import { useUserData } from "../../context/UserDataContext";
// services
import User from "../../services/user";
import Gupport from "../../services/gupport";
import { Storage, StorageKeys } from "../../services/storage";
import { merge } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { MenuItems, MenuItem, MenuItemSubMenu } from "../../types/menu";

const OTHER_MENU = {
	type: "subMenu",
	id: "other",
	label: "menu.other",
	nested: true,
	isVisible: () => (Storage.get(StorageKeys.expertMode)),
	singleDivider: true,
	items: [
		{
			type: "entry",
			id: "logs",
			label: "menu.logs",
			route: "/logs",
			icon: icons.Log,
		},
		{
			type: "entry",
			id: "settings",
			label: "menu.settings",
			route: "/settings",
			icon: icons.Settings,
		},
	],
} as const satisfies MenuItemSubMenu;

const MENU_ITEMS = [
	{
		type: "entry",
		id: "dashboard",
		label: "menu.dashboard",
		route: "/welcome",
		icon: icons.Dashboard,
		action: "getGatewayStats",
	},
	// {
	// 	type: "entry",
	// 	label: 'Solr Cluster',
	// 	route: null,
	// 	icon: icons.SolrCluster,
	// },
	{
		type: "entry",
		id: "gateways",
		label: "menu.gateways",
		route: "/gateways",
		icon: "add/add-hub.svg",
	},
	{
		type: "entry",
		id: "users",
		label: "menu.users",
		route: "/users",
		icon: "navigation/users.svg",
	},
	// {
	// 	type: "entry",
	// 	label: 'Cloud Storage',
	// 	route: null,
	// 	icon: icons.CloudStorage,
	// },
	{
		type: "subMenu",
		id: "server",
		label: "menu.playground",
		nested: true,
		items: [
			{
				type: "entry",
				id: "statistics",
				label: "menu.statistics",
				route: "/stats/gerver",
				icon: icons.GerverCluster,
				action: "getStats",
			},
			{
				type: "entry",
				id: "serverLog",
				label: "menu.serverLog",
				route: "/glogs",
				icon: icons.Log,
				action: "getLogs",
			},
			// {
			// 	type: "entry",
			// 	id: "glient",
			// 	label: "menu.glient",
			// 	route: "/playground/glient",
			// 	icon: icons.Playground,
			// 	action: "getGlientPlayground",
			// },
			{
				type: "entry",
				id: "gupport",
				label: "menu.gupport",
				route: "/playground/gupport",
				icon: icons.Playground,
				action: "getGupportPlayground",
			},
			{
				type: "entry",
				id: "supportUser",
				label: "menu.supportUser",
				route: "/support/users",
				icon: "navigation/users.svg",
				levels: ["sup_user_read"],
			},
			{
				type: "entry",
				id: "teditor",
				label: "menu.teditor",
				route: "/teditor",
				icon: icons.TEditorIcon,
				action: "getMetadataEditor",
			},
			{
				type: "entry",
				id: "backup",
				label: "menu.backup",
				route: "/backups",
				icon: icons.GerverCluster,
				levels: ["backup_read"],
			},
		],
	},
	{
		type: "subMenu",
		id: "utilities",
		label: "menu.utilities",
		nested: true,
		singleDivider: true,
		items: [
			{
				type: "entry",
				id: "localization",
				label: "menu.localization",
				route: "/utilities/localization",
				icon: icons.Localization,
			},
		],
	},
	OTHER_MENU,
	{
		type: "entry",
		id: "general-settings",
		label: "menu.generalSettings",
		route: "/general-settings",
		icon: icons.Settings,
	},
	{
		type: "entry",
		id: "about",
		label: "menu.about",
		route: "/about",
		icon: "navigation/support.svg",
	},
] as const satisfies MenuItems;

type Props = {
	activeRoute: string;
};

const NavigationDrawerMenu = ({ activeRoute }: Props)	=> {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { expertMode } = useExpertMode();
	const { ready } = useUserData();

	const shouldShow = (item: MenuItem | undefined) => {
		if (item !== undefined) {
			if (!item.levels && !item.action) {
				return true;
			}
			if (item.action && !Gupport[item.action]) {
				return false;
			}
			if (item.levels && !User.hasAnyLevel(item.levels)) {
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		const activeItem = MENU_ITEMS.find((menuItem) => (menuItem.route === activeRoute));
		if (!shouldShow(activeItem)) {
			const menuItemShow = MENU_ITEMS.find((menuItem) => (shouldShow(menuItem)));
			navigate(menuItemShow.route);
		}
	}, [ready]);

	const getFilteredMenuItems = (items: MenuItems) => (
		items.filter((item) => {
			if (item.isVisible && !item.isVisible()) {
				return false;
			}
			if (!shouldShow(item)) {
				return false;
			}
			if (item.nested && !item.items.some((item) => (shouldShow(item)))) {
				return false;
			}
			return true;
		})
	);

	const renderNestedMenu = (menuItems: MenuItems, nested: boolean, activeRoute: string) => {
		const baseStyle = nested ? { paddingLeft: "44px" } : {};

		return getFilteredMenuItems(menuItems).map((item, index) => {
			const color = (item.route === activeRoute) ? theme.palette.primary.main : theme.palette.text.secondary;
			const style = {...baseStyle, color: color};
			const icon = (typeof item.icon === "string")
				? <Svg src={item.icon} size={24} color={color} />
				: item.icon ? <item.icon htmlColor={color} /> : null;

			if (item.onSelected) {
				return (
					<ListItemButton key={index} style={style} onClick={item.onSelected}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItemButton>
				);
			}
			if (activeRoute === item.route) {
				return (
					<ListItem key={index} style={style}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItem>
				);
			}
			if (item.nested) {
				const nestedMenuItems = merge(item, { items: getFilteredMenuItems(item.items) });
				return (
					<NavigationNestedMenu
						key={index}
						item={nestedMenuItems}
						activeRoute={activeRoute}
						renderNestedMenu={renderNestedMenu}
					/>
				);
			}
			return (
				<Link key={index} to={item.route} className="no-decoration">
					<ListItemButton style={style}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItemButton>
				</Link>
			);
		});
	};

	if (ready) {
		return renderNestedMenu(MENU_ITEMS, false, activeRoute);
	}
	if (expertMode) {
		return renderNestedMenu([OTHER_MENU], true, activeRoute);
	}
	return null;
};

export default NavigationDrawerMenu;
