import { useState, useEffect, useCallback } from "react";
import isEqual from "lodash.isequal";
// services
import { DEFAULT_REPLACE_DATA, Storage } from "../services/storage";
// types
import type { StorageKey, ReplaceData, StorageValueWithDefault } from "../services/storage";

export { StorageKeys } from "../services/storage";

/**
 * @param storageKey `StorageKeys.XXX`
 * @param replaceData DON'T DO `useStorage(StorageKeys.XXX, { Y: y })`! Instead define object outside the component or use `useMemo()`
 * @param fallbackReplaceData DON'T DO `useStorage(StorageKeys.XXX, { Y: y }, { Y: z })`! Instead define object outside the component or use `useMemo()`
 *
 * @example const [replaceData, fallbackReplaceData] = useMemo(() => ([{ GWID: gateway.id }, { GWID: gateway.srcGw }] as const), [gateway.id, gateway.srcGw]);
 * const [value, setValue] = useStorage(StorageKeys.XXX, replaceData, fallbackReplaceData);
 */
const useStorage = <SK extends StorageKey>(storageKey: SK, replaceData: ReplaceData = DEFAULT_REPLACE_DATA, fallbackReplaceData?: ReplaceData) => {
	const [value, setValue] = useState(Storage.get(storageKey, replaceData, fallbackReplaceData));

	useEffect(() => {
		setValue(Storage.get(storageKey, replaceData, fallbackReplaceData));
	}, [storageKey, replaceData, fallbackReplaceData]);

	useEffect(() => {
		const handleChanged = (changeValue: StorageValueWithDefault<SK>, changeReplaceData: ReplaceData) => {
			if (isEqual(changeReplaceData, replaceData)) {
				setValue(changeValue);
			}
		};

		const handleRemoved = (removeReplaceData: ReplaceData) => {
			if (isEqual(removeReplaceData, replaceData)) {
				setValue(Storage.get(storageKey, replaceData, fallbackReplaceData));
			}
		};

		Storage.on(`${storageKey}Changed`, handleChanged);
		Storage.on(`${storageKey}Removed`, handleRemoved);

		return () => {
			Storage.off(`${storageKey}Changed`, handleChanged);
			Storage.off(`${storageKey}Removed`, handleRemoved);
		};
	}, [storageKey, replaceData, fallbackReplaceData]);

	const handleSetValue = useCallback((storageValue: StorageValueWithDefault<SK> | ((prevStorageValue: StorageValueWithDefault<SK>) => StorageValueWithDefault<SK>)) => {
		const _storageValue = (typeof storageValue === "function")
			? storageValue(Storage.get(storageKey, replaceData, fallbackReplaceData))
			: storageValue;
		Storage.set(storageKey, _storageValue, replaceData);
	}, [storageKey, replaceData, fallbackReplaceData]);

	return [value, handleSetValue] as const;
};

export default useStorage;
