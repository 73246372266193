import { EventEmitter } from "events";
import Constants from "./constants";
import { getServerOption } from "./serverOption";
import { setSocketId, checkIsAuthenticated } from "./authenticator";
// types
import type { Channel, WebSocketUrl } from "../types/config";
import type {
	WsType, HandlerId,
	MessageTxKnown, PayloadTxKnown, GenericCmdLogin, GenericCmdKeepAlive,
	MessageErrorRX, MessageRxKnown, PayloadRxKnown, GenericMsgResponseLogin,
	MsgData, MsgDataUnknown, MsgDataWithPayload,
	MessageResponseHandler, FilterMsgByPayload, RxCallback,
} from "../types/roc-ws";

const HEARTBEAT_SECONDS = 55;

const MSG_DATA_UNKNOWN = {
	reason: "unknown",
} as const satisfies MsgDataUnknown;

/**
 * @event connecting
 * @event connected
 * @event disconnected
 * @event error
 * @event expired
 * @event loggedIn
 * @event message-tx
 * @event message-rx
 */
abstract class RocWs<WT extends WsType = WsType> extends EventEmitter {

	#wsType: WT;
	#channel: Channel | undefined = undefined;
	#url: WebSocketUrl | undefined = undefined;
	#loginPayload: Partial<GenericCmdLogin> = {};

	#ready = false;
	#connecting = false;
	#disconnecting = false;

	#messageResponseTimeouts = new Map<HandlerId, number>();
	#messageResponseHandlers = new Map<HandlerId, MessageResponseHandler<WT>>();

	#connectRetryId: number | undefined = undefined;
	#heartbeatId: number | undefined = undefined;

	#connectRetryTimeout = 5000;

	#ws: WebSocket | null = null;

	constructor(wsType: WT) {
		super();

		this.setMaxListeners(32);

		this.#wsType = wsType;

		this.handleSendHeartbeat = this.handleSendHeartbeat.bind(this);
		// setup ws event handlers
		this.handleWsOpen = this.handleWsOpen.bind(this);
		this.handleWsClose = this.handleWsClose.bind(this);
		this.handleWsMessage = this.handleWsMessage.bind(this);
		this.handleWsError = this.handleWsError.bind(this);

		this.on("error", (error) => (console.warn(error))); // TODO: remove?
	}

	public get ready(): boolean { // TODO: remove
		return this.#ready;
	}

	public get connecting(): boolean { // TODO: remove
		return this.#connecting;
	}

	public get disconnecting(): boolean { // TODO: remove
		return this.#disconnecting;
	}

	#cleanup(): void {
		window.clearTimeout(this.#connectRetryId);
		this.#stopHeartbeat();
	}

	protected setLoginPayload(loginPayload: Partial<GenericCmdLogin>): void {
		this.#loginPayload = loginPayload;
	}

	#getWsUrlFromType(): WebSocketUrl {
		const { glientWsUrl, gupportWsUrl, ccWsUrl } = getServerOption();
		switch (this.#wsType) {
			case Constants.WsType.Glient:
				return glientWsUrl;
			case Constants.WsType.Gupport:
				return gupportWsUrl;
			case Constants.WsType.CC:
				return ccWsUrl;
			default:
				throw new Error("Unknown type");
		}
	}

	public async connect(): Promise<void> {
		if (this.#ready || this.#connecting || this.#disconnecting) {
			return;
		}

		this.#connecting = true;
		this.emit("connecting");

		const { channel } = getServerOption();

		this.#channel = channel;
		this.#url = this.#getWsUrlFromType();

		try {
			const isAuthenticated = await checkIsAuthenticated();
			if (isAuthenticated) {
				this.#ws = new WebSocket(this.#url);
				this.#ws.addEventListener("open", this.handleWsOpen);
				this.#ws.addEventListener("close", this.handleWsClose);
				this.#ws.addEventListener("message", this.handleWsMessage);
				this.#ws.addEventListener("error", this.handleWsError);
			} else {
				this.#connecting = false;
				const msgData = {
					reason: "loginFailed",
					payload: { message: "header auth failed" },
					url: this.#url,
				} as const satisfies MsgDataWithPayload;
				this.emit("loginFailed", msgData);
			}
		} catch (error) {
			this.#connecting = false;
			console.error(error);
			this.emit("debugMessage", "glient connect failed");
			this.emit("error", error);
			this.#reconnectWithTimeout();
		}
	}

	public async disconnect(msgData: MsgData = MSG_DATA_UNKNOWN): Promise<void> {
		if (this.#disconnecting) {
			return;
		}

		this.#disconnecting = true;
		this.emit("disconnecting");

		this.#abortAll();
		this.#cleanup();

		await this.#closeWebsocket();

		setSocketId(undefined);

		this.#ready = false;
		this.#connecting = false;
		this.#disconnecting = false;
		this.emit("disconnected", msgData);
	}

	async #closeWebsocket(): Promise<void> {
		return new Promise((resolve) => {
			if (this.#ws) {
				this.#ws.removeEventListener("open", this.handleWsOpen);
				this.#ws.removeEventListener("close", this.handleWsClose);
				this.#ws.removeEventListener("message", this.handleWsMessage);
				this.#ws.removeEventListener("error", this.handleWsError);

				if (this.#ws.readyState === WebSocket.CLOSED) {
					this.#ws = null;
					resolve();
				} else {
					const closeHandler = (): void => {
						if (this.#ws) {
							this.#ws.removeEventListener("close", closeHandler);
							this.#ws = null;
						}
						resolve();
					};
					this.#ws.addEventListener("close", closeHandler);
					this.#ws.close(1000);
				}
			} else {
				resolve();
			}
		});
	}

	#reconnectWithTimeout(): void {
		this.#connectRetryId = window.setTimeout(async () => {
			await this.#reconnect();
		}, this.#connectRetryTimeout);
	}

	async #reconnect(): Promise<void> {
		this.emit("debugMessage", `perform reconnect()`);
		this.once("disconnected", async () => {
			await this.connect();
		});
		await this.disconnect();
	}

	#startHeartbeat(): void {
		this.#stopHeartbeat();
		this.#heartbeatId = window.setInterval(this.handleSendHeartbeat, HEARTBEAT_SECONDS * 1000);
	}

	#stopHeartbeat(): void {
		window.clearInterval(this.#heartbeatId);
		this.#heartbeatId = undefined;
	}

	private handleSendHeartbeat(): void {
		const cmd = {
			action: "keepAlive",
			data: new Date().toISOString(),
		} as const satisfies GenericCmdKeepAlive;
		this.send(cmd);
	}

	private handleWsOpen(/*event: Event*/): void {
		this.emit("connected");
		this.on("message-rx", this.#messageRxHandler.bind(this));
		this.#txLogin();

		this.#startHeartbeat();
	}

	private handleWsClose(event: CloseEvent): void {
		setSocketId(undefined);

		this.#ready = false;
		// this.#disconnecting = true;
		// this.emit("disconnecting");

		this.#abortAll();
		this.off("message-rx", this.#messageRxHandler.bind(this));

		this.#cleanup();

		this.emit("debugMessage", `handleWSClosed event was clean? ${event.wasClean} - if not clean, we reconnectWithTimeout`);

		if (!event.wasClean) {
			this.#reconnectWithTimeout();
		}
	}

	private handleWsMessage(event: MessageEvent<string>): void {
		try {
			const payload = JSON.parse(event.data) as PayloadRxKnown<WT>;

			const msg = {
				dir: "RX",
				error: (payload.status === "error") ? new Error(payload.data ?? "Unknown error") : null,
				payload: payload,
				raw: event.data,
			} as const satisfies MessageRxKnown<WT>;
			this.emit("message-rx", msg);
		} catch (error) {
			const msg = {
				dir: "RX",
				error: error as Error,
				payload: null,
				raw: event.data,
			} as const satisfies MessageErrorRX;
			this.emit("message-rx", msg);
		}
	}

	private handleWsError(event: Event): void {
		this.emit("error", event);
		this.#reconnectWithTimeout();
	}

	/**
	 * Use `const send = useSend()` hook if possible
	 * TODO: make async
	 */
	public send<P extends PayloadTxKnown<WT>>(payload: P, callback: RxCallback<WT, P> | null = null, timeout: number = 30000): HandlerId {
		payload.channel ??= this.#channel!;
		payload.requestId ??= globalThis.crypto.randomUUID() as HandlerId;

		if (callback) {
			this.#setResponseListener(payload, timeout, callback);
		}

		void this.#trySend(payload, callback, timeout);

		return payload.requestId;
	}

	async #trySend<P extends PayloadTxKnown<WT>>(payload: P, callback: RxCallback<WT, P> | null, timeout: number): Promise<void> {
		if (this.#ws?.readyState === WebSocket.OPEN) {
			this.#sendPayload(payload, timeout, callback);
		} else {
			this.once("ready", () => {
				this.#sendPayload(payload, timeout, callback);
			});
			if (this.#ws?.readyState !== WebSocket.CONNECTING) {
				await this.connect();
			}
		}
	}

	#sendPayload<P extends PayloadTxKnown<WT>>(payload: P, timeout: number, callback: RxCallback<WT, P> | null = null): void {
		try {
			this.#ws!.send(JSON.stringify(payload));
			const msg = {
				dir: "TX",
				error: null,
				payload: payload,
			} as const satisfies MessageTxKnown<WT>;
			this.emit("message-tx", msg);
		} catch (error) {
			this.abort(payload.requestId!);

			const msg = {
				dir: "TX",
				error: error as Error,
				payload: payload,
			} as const satisfies MessageTxKnown<WT>;
			this.emit("message-tx", msg);
			if (callback) {
				callback(error as Error);
			} else {
				throw error as Error;
			}
		}
	}

	#setResponseListener<P extends PayloadTxKnown<WT>>(payload: P, timeout: number, callback: RxCallback<WT, P>): void {
		const handlerId = payload.requestId!;

		if (timeout > 0) {
			const timeoutId = window.setTimeout(() => {
				this.abort(handlerId);
				callback(new Error(`Timeout! handlerId: ${handlerId}`));
			}, timeout);
			this.#messageResponseTimeouts.set(handlerId, timeoutId);
		}

		const messageResponseHandler: MessageResponseHandler<WT, P> = (msg) => {
			this.abort(handlerId);

			if (msg.error === null) {
				callback(null, msg);
			} else {
				callback(msg.error);
			}
		};
		this.#messageResponseHandlers.set(handlerId, messageResponseHandler as unknown as MessageResponseHandler<WT>);
	}

	#messageRxHandler(msg: MessageRxKnown<WT> | MessageErrorRX): void {
		if (msg.payload && "responseId" in msg.payload && this.#messageResponseHandlers.has(msg.payload.responseId)) {
			this.#messageResponseHandlers.get(msg.payload.responseId)?.(structuredClone(msg as FilterMsgByPayload<WT, PayloadTxKnown<WT>>)); // TODO remove on event messaging rework
		}
	}

	#abortAll(): void {
		for (const handlerId of this.#messageResponseHandlers.keys()) {
			this.abort(handlerId);
		}
	}

	public abort(handlerId: HandlerId): boolean {
		window.clearTimeout(this.#messageResponseTimeouts.get(handlerId));
		this.#messageResponseTimeouts.delete(handlerId);
		return this.#messageResponseHandlers.delete(handlerId);
	}

	#txLogin(): void {
		this.off("message-rx", this.#rxLogin);
		this.once("message-rx", this.#rxLogin);

		const cmd = {
			action: "login",
			...this.#loginPayload,
		} as const satisfies GenericCmdLogin;
		this.send(cmd);
	}

	async #rxLogin(msg: MessageRxKnown<WT> | MessageErrorRX): Promise<void> {
		if (msg.payload?.info === "login") {
			const loginMsg = structuredClone(msg as GenericMsgResponseLogin); // TODO remove on event messaging rework
			this.#connecting = false;
			if (loginMsg.payload.status === "ok") {
				// logged in
				await this.postLogin(loginMsg);
			} else {
				const msgData = {
					reason: "loginFailed",
					payload: loginMsg.payload,
					url: this.#url!,
				} as const satisfies MsgDataWithPayload;
				await this.disconnect(msgData);
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	public async postLogin(msg: GenericMsgResponseLogin): Promise<void> {
		this.#ready = true;
		this.emit("ready", structuredClone(msg));
	}

}

export default RocWs;
