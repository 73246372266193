// services
import Constants from "./constants";
import { setSocketId } from "./authenticator";
import RocWs from "./roc-ws";
// types
import type { Table, ApiSchemasTable } from "../types/roc-table";
import type { RxCallback } from "../types/roc-ws";
import type { CmdGetTable } from "../types/message";
import type { GupportPayloadTxKnown, MsgResponseLogin } from "../types/gupport";

class Gupport extends RocWs<typeof Constants.WsType.Gupport> {

	public apiSchemas: ApiSchemasTable = [];
	public apiSchemasGlient: ApiSchemasTable<typeof Constants.WsType.Glient> = [];

	constructor() {
		super(Constants.WsType.Gupport);
	}

	public override async postLogin(msg: MsgResponseLogin): Promise<void> {
		setSocketId(msg.payload.data.socketId);
		await this.#requestApiSchemas();
		await super.postLogin(msg);
	}

	async #requestApiSchemas() {
		this.#removeActionMethods(this.apiSchemas.filter((schema) => (schema.data.dir === "TX")));
		this.apiSchemas = [];
		this.apiSchemasGlient = [];
		try {
			const table = await this.#fetchApiSchemas();
			this.apiSchemas = [];
			this.apiSchemasGlient = [];
			this.#addApiSchemas(table);
			this.#addActionMethods(table.filter((schema) => (schema.data.dir === "TX" && schema.data.api === Constants.WsType.Gupport)) as ApiSchemasTable<typeof Constants.WsType.Gupport>);
		} catch (error) {
			this.emit("error", error);
		}
	}

	async #fetchApiSchemas() {
		return new Promise<Table<typeof Constants.TableName.ApiSchemas>>((resolve, reject) => {
			const cmd = {
				action: "getTable",
				tableName: Constants.TableName.ApiSchemas,
			} as const satisfies CmdGetTable;
			this.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok" && msg.payload.tableName === Constants.TableName.ApiSchemas) {
					resolve(msg.payload.data);
				} else {
					reject(error ?? new Error(JSON.stringify(msg?.payload, null, 2)));
				}
			});
		});
	}

	private sendAction<GPTK extends GupportPayloadTxKnown>(actionName: GPTK["action"], payload: GPTK, callback: RxCallback<typeof Constants.WsType.Gupport, GPTK>) {
		const cmd = {
			...payload ?? {},
			action: actionName,
		} as const satisfies GPTK;
		return this.send(cmd, callback);
	}

	#addApiSchemas(schemas: ApiSchemasTable) {
		const gupportSchemas = schemas.filter((schema) => (schema.data.api === Constants.WsType.Gupport));
		const glientSchemas = schemas.filter((schema) => (schema.data.api === Constants.WsType.Glient));
		this.apiSchemas = this.apiSchemas.concat(gupportSchemas);
		this.apiSchemasGlient = this.apiSchemasGlient.concat(glientSchemas as ApiSchemasTable<typeof Constants.WsType.Glient>);
	}

	#addActionMethods(schemas: ApiSchemasTable<typeof Constants.WsType.Gupport>) {
		schemas.forEach((schema) => {
			const action = schema.data.payload.action;
			this[action] = this.sendAction.bind(this, action);
		});
	}

	#removeActionMethods(schemas: ApiSchemasTable) {
		schemas.forEach((schema) => {
			const action = schema.data.payload.action;
			delete this[action]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
		});
	}

}

export default (new Gupport());
